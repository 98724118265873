/* global jQuery qualys _ Swiper:false tippy  */
window.qualys = window.qualys || {};

/*
 * helper function for parsing Query strings
 */
(function () {
	"use strict";

	/*
	 * return object with param names and values
	 */
	function parseQueryString(queryString) {
		var params, readKey, key, value, characters, c, i, l, decoded;

		queryString = queryString || "";
		readKey = true;
		key = value = "";
		params = {};
		characters = queryString.split("");

		for (i = 0, l = characters.length; i < l; i += 1) {
			c = characters[i];

			if ((c === "?" && i === 0) || c === "&" || c === ";") {
				readKey = true;
			} else {
				if (c === "=") {
					readKey = false;
				} else {
					if (readKey) {
						key += c;
					} else {
						value += c;
					}
				}
			}

			if (c === "&" || c === ";" || i === l - 1) {
				decoded = {
					key: decodeURIComponent(key.replace(/\+/g, " ")),
					value: decodeURIComponent(value.replace(/\+/g, " ")),
				};

				// put multiple values for the same key into an array
				if (params.hasOwnProperty(decoded.key)) {
					if (params[decoded.key] instanceof Array) {
						params[decoded.key] = params[decoded.key].concat(
							decoded.value
						);
					} else {
						params[decoded.key] = [params[decoded.key]].concat(
							decoded.value
						);
					}
				} else {
					params[decoded.key] = decoded.value;
				}

				key = value = "";
			}
		}

		return params;
	}
	window.qualys = window.qualys || {};
	window.qualys.parseQueryString = parseQueryString;

	/*
	 * return array of objects with cookie names and values
	 */
	function parseCookieString(cookieString) {
		var cookies;

		// basic DoS preventation
		if (cookieString.length > 20 * 4096) {
			throw new Error(
				"Cookie string too long. length = " + cookieString.length
			);
		}

		cookies = cookieString.split(/;\s*/).map(function (cs) {
			// basic DoS preventation
			if (cs.length > 4096) {
				throw new Error("Cookie too big. length = " + cs.length);
			}

			return {
				name: cs.slice(0, cs.indexOf("=")),
				value: cs.slice(cs.indexOf("=") + 1),
			};
		});

		return cookies;
	}

	window.qualys = window.qualys || {};
	window.qualys.parseCookieString = parseCookieString;
})();
/*
 * save UTM parameters in URL as session cookies
 * if a link contains the class "appendUTM",
 * then append the UTM params to the link
 */
(function () {
	"use strict";

	// get url query string parameters
	var urlParams = qualys.parseQueryString(window.location.search);

	// loop over each url query string parameter
	Object.entries(urlParams).forEach(([key, value]) => {
		// console.log(`${key}: ${value}`);
		key = key.toLowerCase();
		// if the parameter is a UTM parameter
		if (key.startsWith("utm_")) {
			// set a session cookie for UTM parameter
			document.cookie = [
				`qualys.${key}=${value}`,
				//"domain=.qualys.com",
				"path=/",
				"secure=yes",
			].join("; ");
		}
	});

	// get all cookies
	var cookies = qualys.parseCookieString(document.cookie);

	var utms = [];

	// loop over each cookie
	cookies.forEach((cookie) => {
		// save each UTM cookie in the utms array
		if (cookie.name.startsWith("qualys.utm_")) {
			var utmName = cookie.name.replace("qualys.", "");
			var obj = {};
			obj[utmName] = cookie.value;
			utms.push(obj);
		}
	});

	// console.log(utms);

	// find all links in page that have the class "appendUTM"
	var elements = document.querySelectorAll(".appendUTM");
	// convert NodeList to array
	var links = Array.from(elements);

	var link;
	// loop over matching links
	for (link of links) {
		// get the link href value
		var href = link.getAttribute("href");
		var url = new URL(href);
		// loop over each UTM parameter found in cookies
		for (const index in utms) {
			// console.log(index, utms[index]);
			for (const [key, value] of Object.entries(utms[index])) {
				// console.log(key, value);
				// set the utm name/value pair in the query string
				url.searchParams.set(key, value);
			}
		}

		// console.log(url.href);

		// update the href value
		link.setAttribute("href", url.href);
	}
})();

/*
 * util - generate UUID from Math.random()
 */
(function registerQualysUtils() {
	"use strict";

	/**
	 * generateUUID - create a random UUID string
	 * @returns {String} uuid
	 */
	function generateUUID() {
		var template, uuid;

		template = "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx";
		uuid = template.replace(/[xy]/g, function generateRandomChar(match) {
			var number, value;

			number = (Math.random() * 16) | 0;
			if (match === "x") {
				value = number;
			} else {
				value = (number & 0x3) | 0x8;
			}

			return value.toString(16);
		});

		return uuid;
	}

	window.qualys = window.qualys || {};
	window.qualys.utils = window.qualys.utils || {};
	window.qualys.utils.generateUUID = generateUUID;
})();

/**
 * template functions
 */

(function () {
	"use strict";

	// initiate tippy

	tippy("[data-tippy-content]");

	/**
	 * image with content accordion
	 */

	(function ($) {
		"use strict";

		$(document).ready(function () {
			if ($(".js-q-accordion").length) {
				$(".js-q-accordion").accordiom({
					speed: 500,
					showFirstItem: true,
					autoClosing: true,
					openAll: false,
					buttonBelowContent: false,
					beforeChange: function (button) {
						if (!button.classList.contains("on")) {
							var showImage = button.getAttribute("data-image");
							var activeImage = $(
								".js-accordion-images img." + showImage
							);
							$(".accordionImage").not(".active").hide();

							if (!activeImage.hasClass("active")) {
								$(".accordionImage.active")
									.fadeOut("fast", function () {
										$("." + showImage)
											.fadeIn("slow")
											.addClass("active");
									})
									.removeClass("active");
							}
						}
					},
					afterchange: function () { },
					onLoad: function () {
						$(".js-accordion-images")
							.children(".accordionImage")
							.first()
							.removeClass("hidden")
							.addClass("active");
					},
				});
			}
		});
	})(jQuery);

	// Swiper for Related Products
	let relatedProductsSwiper = null;
	const initRelatedProductsSwiper = () => {
		relatedProductsSwiper = new Swiper(".products-container", {
			slidesPerView: "auto",
			centeredSlides: false,
			spaceBetween: window.innerWidth <= 768 ? 12 : 32,
		});
	};

	const handleRelatedProductsSwiper = () => {
		if (window.innerWidth <= 768) {
			if (
				!relatedProductsSwiper &&
				document.querySelector(".products-container")
			) {
				initRelatedProductsSwiper();
			}
		} else if (relatedProductsSwiper !== null) {
			if (Array.isArray(relatedProductsSwiper)) {
				for (let productSwiper of relatedProductsSwiper) {
					productSwiper.destroy();
				}
			} else {
				relatedProductsSwiper.destroy();
			}
			relatedProductsSwiper = null;
		}
	};

	window.addEventListener("load", handleRelatedProductsSwiper);
	window.addEventListener(
		"resize",
		_.debounce(handleRelatedProductsSwiper, 40)
	);

	// Swiper for More Reasons to Try
	let moreReasonsSwiper = null;
	const initiateMoreReasonsSwiper = () => {
		moreReasonsSwiper = new Swiper(".solutions-container", {
			slidesPerView: "auto",
			centeredSlides: false,
			spaceBetween: 16,
		});
	};

	const handleMoreReasonsSwiper = () => {
		if (
			!moreReasonsSwiper &&
			document.querySelector(".solutions-container")
		) {
			initiateMoreReasonsSwiper();
		}
	};
	window.addEventListener("load", handleMoreReasonsSwiper);

	// Swiper for More Reasons to Try -- grey grid

	let greyGridSwiper = null;
	const initGreyGridSwiper = () => {
		greyGridSwiper = new Swiper(".grey-grid-container", {
			slidesPerView: "auto",
			centeredSlides: false,
			spaceBetween: 12,
		});
	};

	const handleGreyGridSwiper = () => {
		if (window.innerWidth <= 768) {
			if (!greyGridSwiper && document.querySelector(".grey-grid-container")) {
				initGreyGridSwiper();
			}
		} else if (greyGridSwiper !== null) {
			if (Array.isArray(greyGridSwiper)) {
				for (let greySwiper of greyGridSwiper) {
					greySwiper.destroy();
				}
			} else {
				greyGridSwiper.destroy();
			}
			greyGridSwiper = null;
		}
	};
	window.addEventListener("load", handleGreyGridSwiper);
	window.addEventListener("resize", _.debounce(handleGreyGridSwiper, 40));

	// Swiper for customerStories
	let testimonalSwiper = null;
	const initiatetestimonalSwiper = () => {
		testimonalSwiper = new Swiper(".customerStories", {
			slidesPerView: "auto",
			centeredSlides: false,
			spaceBetween: 16,
		});
	};

	const handletestimonalSwiper = () => {
		if (!testimonalSwiper && document.querySelector(".customerStories")) {
			initiatetestimonalSwiper();
		}
	};

	window.addEventListener("load", handletestimonalSwiper);

	// FAQ accordion

	(function ($) {
		"use strict";

		$(document).ready(function () {
			if ($(".faqButton").length) {
				$(".faqButton").each(function () {
					var faqTab = $(this),
						faqContent = faqTab.next();

					faqTab.on("click", function () {
						faqContent.slideToggle("fast");
						faqTab.toggleClass("active");
					});
				});
			}
		});
	})(jQuery);

	// video player components

	(function ($) {
		"use strict";

		// play the targeted video (and hide the poster frame)
		function videoPlay($wrapper) {

			var $iframe = $wrapper.find(".js-videoIframe");
			var src = $wrapper.data("src");

			if (src !== undefined && src !== null) {
				src = String(src);
				if (src.indexOf("?") !== -1) {
					src += "&";
				} else {
					src += "?";
				}
			} else {
				console.error("Error: 'src' is undefined or null.");
			}

			// hide poster
			$wrapper.addClass("videoWrapperActive");
			$iframe.append("<iframe class=\"absolute top-0 left-0 right-0 bottom-0 w-full h-full bg-transparent overflow-hidden\" autoplay=\"true\" frameborder=\"0\" allowTransparency=\"true\" allowfullscreen allow=\"autoplay; fullscreen\" src=\"https://player.vimeo.com/video/" + src + "autoplay=1&title=0&byline=0&portrait=0&share=0\"></iframe>");
		}

		// poster frame click event
		$(document).on("click", ".js-videoPoster", function (ev) {

			ev.preventDefault();
			var $poster = $(this);
			var $wrapper = $poster.closest(".js-videoWrapper");

			videoPlay($wrapper);

		});

	})(jQuery);


	// Swiper for image text container

	let imageTextSwiper = null;
	const initImageTextSwiper = () => {
		imageTextSwiper = new Swiper(".image-text-container", {
			slidesPerView: "auto",
			centeredSlides: false,
			spaceBetween: 12,
		});
	};

	const handleImageTextSwiper = () => {
		if (window.innerWidth <= 768) {
			if (
				!imageTextSwiper &&
				document.querySelector(".image-text-container")
			) {
				initImageTextSwiper();
			}
		} else if (imageTextSwiper !== null) {
			if (Array.isArray(imageTextSwiper)) {
				for (let imgSwiper of imageTextSwiper) {
					imgSwiper.destroy();
				}
			} else {
				imageTextSwiper.destroy();
			}
			imageTextSwiper = null;
		}
	};
	window.addEventListener("load", handleImageTextSwiper);
	window.addEventListener("resize", handleImageTextSwiper);

	//Testimonial Dekstop Swiper Slider

	// let customerTestimonialSwiper = null;
	// let codeHasRun = false;

	let contentSwiper = new Swiper(".js-tm-content-slider", {
		effect: "slide",
		pagination: {
			el: ".testimonial-container .swiper-pagination",
			clickable: true,
		},
		on: {
			init: function () {

				const currentSlide = this.slides[this.activeIndex];
				if (currentSlide) {
					if (currentSlide.hasAttribute("data-video")) {
						const videohref = currentSlide.getAttribute("data-video");


						if (videohref) {
							const testimonialSliderBtn = document.getElementById(videohref);
							testimonialSliderBtn.classList.remove("hidden");
						}
					}
				}
			}
		}
	});
	let imageSwiper = new Swiper(".js-tm-image-slider", {
		effect: "fade",
		allowTouchMove: false
	});

	contentSwiper.on("slideChange", function () {

		imageSwiper.slideTo(contentSwiper.activeIndex);

		const activeSlideIndex = this.activeIndex;

		const activeSlide = this.slides[activeSlideIndex];
		const dataValue = activeSlide.getAttribute("data-video");

		const playBtns = document.querySelectorAll(".js-home-play-btn");

		for (const playBtn of playBtns) {
			playBtn.classList.add("hidden");
		}

		var testimonialSliderBtn;

		if (dataValue) {
			testimonialSliderBtn = document.getElementById(dataValue);
			testimonialSliderBtn.classList.remove("hidden");
		}
	});

	imageSwiper.on("slideChange", function () {
		contentSwiper.slideTo(imageSwiper.activeIndex);
	});

	//Testimonial Mobile Swiper Slider

	let mobileSwiper = null;
	const initMobileSwiper = () => {
		mobileSwiper = new Swiper(".mobile-testimonial-container", {
			slidesPerView: "auto",
			centeredSlides: false,
			spaceBetween: 20,
			pagination: false,
		});
	};

	const handleMobileSwiper = () => {
		if (window.innerWidth <= 768) {
			if (
				!mobileSwiper &&
				document.querySelector(".mobile-testimonial-container")
			) {
				initMobileSwiper();
			}
		} else if (mobileSwiper !== null) {
			if (Array.isArray(mobileSwiper)) {
				for (let mbSwiper of mobileSwiper) {
					mbSwiper.destroy();
				}
			} else {
				mobileSwiper.destroy();
			}
			mobileSwiper = null;
		}
	};
	window.addEventListener("load", handleMobileSwiper);
	window.addEventListener("resize", handleMobileSwiper);

	//Swiper for Cyber Risk Container cards

	let cyberRiskSwiper = null;
	const initCyberRiskSwiper = () => {
		cyberRiskSwiper = new Swiper(".cyber-risk-container", {
			slidesPerView: "auto",
			centeredSlides: false,
			spaceBetween: 28,
		});
	};

	const handleCyberRiskSwiper = () => {
		if (window.innerWidth <= 768) {
			if (!cyberRiskSwiper && document.querySelector(".cyber-risk-container")) {
				initCyberRiskSwiper();
			}
		} else if (cyberRiskSwiper !== null) {
			if (Array.isArray(cyberRiskSwiper)) {
				for (let crSwiper of cyberRiskSwiper) {
					crSwiper.destroy();
				}
			} else {
				cyberRiskSwiper.destroy();
			}
			cyberRiskSwiper = null;
		}
	};
	window.addEventListener("load", handleCyberRiskSwiper);
	window.addEventListener("resize", handleCyberRiskSwiper);

	//Swiper for worldWide container

	let worldWideSwiper = null;
	const initWorldwideSwiper = () => {
		worldWideSwiper = new Swiper(".protection-worldwide", {
			slidesPerView: "auto",
			centeredSlides: false,
			spaceBetween: 28,
			pagination: {
				el: ".protection-worldwide .swiper-pagination",
				clickable: true,
			}
		});
	};

	const handleworldWideSwiper = () => {
		if (window.innerWidth <= 768) {
			if (!worldWideSwiper && document.querySelector(".protection-worldwide")) {
				initWorldwideSwiper();
			}
		}
		else if (worldWideSwiper !== null) {
			if (Array.isArray(worldWideSwiper)) {
				for (let pwSwiper of worldWideSwiper) {
					pwSwiper.destroy();
				}
			} else {
				worldWideSwiper.destroy();
			}
			worldWideSwiper = null;
		}
	};
	window.addEventListener("load", handleworldWideSwiper);
	window.addEventListener("resize", handleworldWideSwiper);

})();

/**
 * Select a random image everytime on page load to use in the
 * trail forms CTA
 */
// Function to get a random variable different from the previous one
function getRandomVariable(previousVariable) {
	const variables = ["1", "2", "3", "4", "5", "6", "7", "8"];
	let randomVariable;

	do {
		randomVariable = variables[Math.floor(Math.random() * variables.length)];
	} while (randomVariable === previousVariable);

	return randomVariable;
}

// Function to set and get the previously chosen variable
function setPreviousVariable(variable) {
	localStorage.setItem("previousVariable", variable);
}

function getPreviousVariable() {
	return localStorage.getItem("previousVariable");
}

// On page load
window.onload = function () {
	// Get the previously chosen variable
	const previousVariable = getPreviousVariable();

	// Get a new random variable different from the previous one
	const newVariable = getRandomVariable(previousVariable);

	// bg image container
	const bgContainer = document.querySelector(".js-bgimage-container");

	if (bgContainer) {

		// Display the selected variable (you can modify this part as per your requirement)
		// console.log("Selected Variable:", newVariable);
		bgContainer.style.backgroundImage = 'url("https://ik.imagekit.io/qualys/image/background/bg-image-' + newVariable + '.jpg")';

		// Set the new variable as the previously chosen variable for the next page load
		setPreviousVariable(newVariable);
	}
};

/**
 * featured screenshot section tabs functionality
 */
(function ($) {

	$(document).ready(function () {
		const $tabs = $(".js-q-laptop-tab");
		const $tabContents = $(".js-q-tab-content");
		const $innerContents = $(".inner-content");
		const $tabContainer = $(".js-featured-laptop");

		let currentIndex = 0;
		let autoplayInterval;

		function showTab(index) {
			$tabContents.addClass("hidden");
			$tabContents.eq(index).removeClass("hidden");
			$innerContents.hide(); // hide all inner contents
			$innerContents.eq(index).slideDown(); // Slide down the inner content of the active tab
			// Pause, reset, and handle looping for previous tab's videos
			$tabContents.not($tabContents.eq(index)).find('video').each(function () {
				this.pause();
				this.currentTime = 0; // Reset playback position to the beginning
				this.loop = false; // Ensure looping is disabled
			});

			// Restart the video of the current tab and enable looping if it has the 'enable-video-autoplay' class
			const currentVideo = $tabContents.eq(index).find('video.enable-video-autoplay')[0];
			if (currentVideo) {
				currentVideo.loop = true; // Enable looping
				currentVideo.currentTime = 0; // Ensure playback starts from the beginning
				currentVideo.play(); // Start playback
			}
		}

		function handleTabClick(index) {
			if (index !== currentIndex) {
				$tabs.removeClass("isActive");
				$tabs.eq(index).addClass("isActive");
				currentIndex = index;
				showTab(index);
			}
		}

		function autoplay() {
			currentIndex = (currentIndex + 1) % $tabs.length;
			showTab(currentIndex);
			$tabs.removeClass("isActive");
			$tabs.eq(currentIndex).addClass("isActive");
		}

		function startAutoplay() {
			// Check screen width before starting autoplay
			if ($(window).width() >= 768 && !isAutoplayDisabled()) {
				autoplayInterval = setInterval(autoplay, 5000);
			}
		}

		function resetAutoplay() {
			clearInterval(autoplayInterval);
			startAutoplay();
		}

		function isAutoplayDisabled() {
			// Check if autoplay should be disabled (e.g., based on a class or URL parameter)
			return $('div').hasClass('disable-autoplay');
		}

		// Add event listeners for mouseover and mouseout on tabs
		$tabs.on("click", function () {
			handleTabClick($tabs.index(this));
		});

		$tabContainer.on("mouseover", function () {
			clearInterval(autoplayInterval);
		});

		$tabContainer.on("mouseout", function () {
			startAutoplay();
		});

		startAutoplay();

		$(window).on("resize", function () {
			resetAutoplay();
		});

	});

})(jQuery);


/**
 * screenshot carousel on laptop partial
 */

(function () {

	document.addEventListener("DOMContentLoaded", function () {

		var swiperElements = document.querySelectorAll(".js-laptop-swiper");

		if (swiperElements) {
			swiperElements.forEach(function (element) {
				new Swiper(element, {
					slidesPerView: 1,
					navigation: false,
					autoplay: true,
					loop: true
				});
			});
		}
	});

})();


/**
 * testimonial section background video
 */

const videoElement = document.getElementById("q_testimonial_video_bg");

const videoFiles = [
	"video-bg-1.webm",
	"video-bg-2.webm",
	"video-bg-3.webm",
	"video-bg-4.webm",
	"video-bg-5.webm",
	"video-bg-6.webm",
	"video-bg-7.webm"
];

// Function to shuffle the array randomly
function shuffleArray(array) {
	for (let i = array.length - 1; i > 0; i--) {
		const j = Math.floor(Math.random() * (i + 1));
		[array[i], array[j]] = [array[j], array[i]];
	}
}


let currentIndex = 0;
let selectedVideos = [];

// Function to select 3 random videos
function selectRandomVideos() {
	selectedVideos = videoFiles.slice(); // Copy the array
	shuffleArray(selectedVideos);
	selectedVideos = selectedVideos.slice(0, 3);
}

// Function to set the next video source
function setNextVideo() {
	if (currentIndex >= selectedVideos.length) {
		// If all videos in the current sequence have been played, reset to the beginning
		currentIndex = 0;
	}

	videoElement.src = "https://cdn2.qualys.com/videos/background/" + selectedVideos[currentIndex];

	// Increment the index for the next video in the sequence
	currentIndex++;

	// Event listener for when the current video ends
	videoElement.addEventListener("ended", setNextVideo);
}

if (videoElement) {
	// Initial call to select the first set of 3 videos
	selectRandomVideos();

	// Initial call to set the first video
	setNextVideo();
}

/**
 * Testimonials carousel global function
 */

(function () {
	"use strict";

	// swiper for testimonial

	let testimonailSwiper = null;
	const sliderContainer = document.querySelector(".js-testimonial-container");

	const initTestimonailSwiper = () => {
		testimonailSwiper = new Swiper(".js-testimonial-container", {
			slidesPerView: 1,
			centeredSlides: false,
			autoplay: {
				delay: 3500, // duration of each slide in ms
				disableOnInteraction: false,
				pauseOnMouseEnter: true
			},
			loop: true,
			pagination: {
				el: ".js-testimonial-swiper-pagination",
				clickable: true,
			}
		});
	};

	const handleTestimonialSwiper = () => {
		if (!testimonailSwiper && document.querySelector(".js-testimonial-container")) {
			initTestimonailSwiper();
		}
	};
	if (sliderContainer) {

		window.addEventListener("load", handleTestimonialSwiper);
		window.addEventListener("resize", handleTestimonialSwiper);

	}

})();

/**
 * tabs swiper
 */

(function () {

	"use strict";

	var tabsContainer = document.querySelector(".js-swiper-tabs");

	// Check if the parent container exists
	if (tabsContainer) {
		// Get all children with the class ".tab"
		var tabElements = tabsContainer.querySelectorAll(".tab");

		// Create an array to store the text content of each child
		var tabTextArray = [];

		// Loop through each child and push its text content to the array
		tabElements.forEach(function (tab) {
			var trimmedText = tab.textContent.trim().replace(/\s+/g, ' ');
			tabTextArray.push(trimmedText);
		});

		let tabSwiper = null;
		var tabSwiperElement = document.querySelector(".js-swiper-tabs-slider");
		var autoplayTabs = tabSwiperElement.getAttribute("data-autoplay") === "true";

		const initTabsSwiper = () => {
			tabSwiper = new Swiper(".js-swiper-tabs-slider", {
				slidesPerView: 1,
				autoplay: autoplayTabs ? {
					delay: 3000,
					disableOnInteraction: false,
					pauseOnMouseEnter: true
				} : false,
				pagination: {
					el: ".js-swiper-tabs",
					clickable: true,
					renderBullet: function (index, className) {
						return '<div class="' + className + '">' + (tabTextArray[index]) + "</div>";
					}
				},
			});
		};

		const handleTabsSwiper = () => {
			if (!tabSwiper && document.querySelector(".js-swiper-tabs-slider")) {
				initTabsSwiper();
			}
		};

		window.addEventListener("load", handleTabsSwiper);
		window.addEventListener("resize", handleTabsSwiper);

	}

})();

/**
 * laptop tabs swiper
 */

(function () {

	"use strict";

	var laptopTabsContainer = document.querySelector(".js-swiper-laptop-tabs");

	// Check if the parent container exists
	if (laptopTabsContainer) {
		// Get all children with the class ".tab"
		var laptopTabElements = laptopTabsContainer.querySelectorAll(".tab");

		// Create an array to store the text content of each child
		var tabTextArray = [];

		// Loop through each child and push its text content to the array
		laptopTabElements.forEach(function (tab) {
			var trimmedText = tab.textContent.trim().replace(/\s+/g, ' ');
			tabTextArray.push(trimmedText);
		});

		let laptopTabSwiper = null;
		var swiperElement = document.querySelector(".js-swiper-laptop-tabs-slider");
		var autoplayValue = swiperElement.getAttribute("data-auoplay") === "true";

		const initTabsSwiper = () => {
			laptopTabSwiper = new Swiper(".js-swiper-laptop-tabs-slider", {
				slidesPerView: 1,
				autoplay: autoplayValue ? {
					delay: 3000,
					disableOnInteraction: false,
					pauseOnMouseEnter: true
				} : false,
				pagination: {
					el: ".js-swiper-laptop-tabs",
					clickable: true,
					renderBullet: function (index, className) {
						return '<div class="' + className + '">' + (tabTextArray[index]) + "</div>";
					}
				},
			});
		};

		const handleTabsSwiper = () => {
			if (!laptopTabSwiper && document.querySelector(".js-swiper-laptop-tabs-slider")) {
				initTabsSwiper();
			}
		};

		window.addEventListener("load", handleTabsSwiper);
		window.addEventListener("resize", handleTabsSwiper);

	}

})();

/**
 * Reasons to switch to Qualys slider
 */
(function () {
	"use strict";
	let reasonsSwiper = null;
	const sliderContainer = document.querySelector(".js-reasons-container");

	const initReasonsSwiper = () => {
		reasonsSwiper = new Swiper(".js-reasons-container", {
			slidesPerView: 1,
			centeredSlides: true,
			autoplay: {
				delay: 3500, // duration of each slide in ms
				disableOnInteraction: false,
				pauseOnMouseEnter: true
			},
			loop: true,
			pagination: {
				el: ".js-reasons-swiper-pagination",
				clickable: true,
			}
		});
	};

	const handleReasonsSwiper = () => {
		if (!reasonsSwiper && document.querySelector(".js-reasons-container")) {
			initReasonsSwiper();
		}
	};

	if (sliderContainer) {
		window.addEventListener("load", handleReasonsSwiper);
		window.addEventListener("resize", handleReasonsSwiper);

	}

})();

/**
 * Resources slider component
 */
(function () {
	"use strict";
	let resourcesSwiper = null;
	const resourcesContainer = document.querySelector(".js-resources-carousel");

	const initResourcesSwiper = () => {
		resourcesSwiper = new Swiper(".js-resources-carousel", {
			slidesPerView: "auto",
			spaceBetween: 35,
			autoHeight: false,
			centeredSlides: false,
			autoplay: false,
			loop: false,
			pagination: false,
			navigation: {
				nextEl: document.querySelector(".js-next-resources"),
				prevEl: document.querySelector(".js-prev-resources")
			}
		});
	};

	const handleResourcesSwiper = () => {
		if (!resourcesSwiper && document.querySelector(".js-resources-carousel")) {
			initResourcesSwiper();
		}
	};

	if (resourcesContainer) {
		window.addEventListener("load", handleResourcesSwiper);
		window.addEventListener("resize", handleResourcesSwiper);

	}

})();

/**
 * Lazy loading images
 */
(function () {
	"use strict";
	if ("loading" in HTMLImageElement.prototype) { // This uses the browser's own native lazy load
		const e = document.querySelectorAll("img[loading=\"lazy\"]");
		e.forEach(e => {
			e.src = e.dataset.src;
		});
	} else { // for Safari
		// This downloads a polyfill from Cloudflare's CDN and makes it happen without the browser
		const e = document.createElement("script");
		e.src = "https://cdnjs.cloudflare.com/ajax/libs/lazysizes/5.2.2/lazysizes.min.js", e.integrity = "sha512-TmDwFLhg3UA4ZG0Eb4MIyT1O1Mb+Oww5kFG0uHqXsdbyZz9DcvYQhKpGgNkamAI6h2lGGZq2X8ftOJvF/XjTUg==", e.crossOrigin = "anonymous", document.body.appendChild(e);
	}
})();

(function () {
	"use strict"; // get url query string parameters
	var e = window.qualys.parseQueryString(window.location.search);
	if ("hideNav" in e) { // find elements
		var t = document.querySelectorAll(".q-js-navigation"),
			a = document.querySelectorAll(".q-js-nav-ation"),
			l = document.querySelectorAll(".qfooter"),
			m = document.querySelectorAll(".js-mobile-menu-control");
		0 < t.length && Array.from(t)[0].classList.add("hidden"), 0 < a.length && Array.from(a)[0].classList.add("hidden"), 0 < l.length && Array.from(l)[0].classList.add("hidden"), 0 < m.length && Array.from(m)[0].classList.add("hidden");
	}
})();


/**
 * a day in the life slider
 */

(function () {

	"use strict";



})();

